import Config from './Config';

class ConsoleLog {
    static isDevelopment = Config.mode === 'development';

    static log(...args: any[]) {
        if (ConsoleLog.isDevelopment) {
            console.log(...args);
        }
    }

    static warn(...args: any[]) {
        if (ConsoleLog.isDevelopment) {
            console.warn(...args);
        }
    }
}

export default ConsoleLog;