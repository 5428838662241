import React from "react";
import { Button, Container, Form } from 'react-bootstrap';
import { useAuth0 } from "@auth0/auth0-react";

const Profile = () => {
  const { isAuthenticated, isLoading, logout, loginWithRedirect } = useAuth0();

  if (isLoading) {
    return <div>Loading ...</div>;
  }
  return (<>
    {isAuthenticated ? (
      // <div>
      //   {/* <img src={user?.picture} alt={user?.name} /> */}
      //   <h2>{user?.name}</h2>
      //   <p>{user?.email}</p>
      // </div>
      <Button onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })} className="btn btn-secondary">Log Out</Button>
    ) : <Button onClick={() => loginWithRedirect()}>Log In</Button>}
    </>
  );
};

export default Profile;