import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';
import { FexcoNavbar } from './Components/Navbar/FexcoNavbar';
import Error from './Components/helpers/Error';
import SignIn from './Components/helpers/SignIn';
import { useAuth0 } from "@auth0/auth0-react";
import Donations from './Components/Donations/Donations';
import {CircularProgress, Box} from '@mui/material';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Navigate replace to="/login" />,
  },
  {
    path: "/error",
    element: <Error/>,
  },
  {
    path: "/login",
    element: <SignIn/>,
  },
  {
    path: "/donations",
    element: <><FexcoNavbar/><Donations /></>,
  },
]);

function App() {
  const { isAuthenticated, isLoading, user, logout } = useAuth0();

  if(isLoading){
    return <Box sx={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
            <CircularProgress sx={{position: 'absolute', top: '50%', left: '50%'}}/>
          </Box>;
  }
  if(isAuthenticated && user){
    let permissionGranted = user['https://expeditions.com/roles'].includes("NgDonationUsers");
    if(!permissionGranted){
      logout({ 
        logoutParams: {
          returnTo: window.location.origin
        }
      });
      return <SignIn/>;
    }
  }else{
    return <SignIn/>;
  }

  let element = handlePublicHosts();
  if(element)
    return element;

  return (
  <>
    <RouterProvider router={router} />
    </>)
}
const handlePublicHosts = () => {
  let currentpath = getCurrentPath();
  if(publicPathNames.includes(currentpath)){
    if(currentpath === succesPathname)
    return <Donations/>
    if(currentpath === errorPathname)
    return <Error/>
  }
}

const getCurrentPath = () => {
return window.location.pathname;
}

const succesPathname = '/success';
const errorPathname = '/error';
const publicPathNames = [succesPathname, errorPathname];

export default App;
