
export default function Error() {
    return <>
    
    <div className="d-flex align-items-center justify-content-center vh-100">
    <div className="text-center">
      <h1 className="display-1 fw-bold">404</h1>
      <p className="fs-3"> <span className="text-danger">Opps!</span> Something went wrong.</p>
      <p className="lead">
        Please try later.
      </p>
    </div>
  </div>
  </>    
  } 