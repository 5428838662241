import React from 'react';
import { CircularProgress, Dialog, DialogContent, DialogContentText } from '@mui/material';

interface ImportProgressPopupProps {
    open: boolean;
    onClose: () => void; // Adjust the type of params as needed
}

const ImportProgresPopup : React.FC<ImportProgressPopupProps> = ({open, onClose}) => {
    const handleClose = (event: any, reason: any) => {
        if (reason !== 'backdropClick') {
            onClose();
        }
    };
  
    return (
      <Dialog open={open} onClose={handleClose}>
        <DialogContent style={{textAlign: 'center'}}>
          <CircularProgress size={60} style={{marginBottom: '16px'}}/>
          <DialogContentText>
            Import file is in progress, please wait patiently.
            <br/>
            It may take up to several minutes.
          </DialogContentText>
        </DialogContent>
      </Dialog>
    );
  };

export default ImportProgresPopup;