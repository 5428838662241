import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { Visibility, Link, DeleteForever} from '@mui/icons-material';
import Config from '../../helpers/Config';
import React, { useState } from 'react';


export function ViewButton({ onClick }: any) {
    const [isHovered, setIsHovered] = useState(false);
  
    const customButtonStyle: React.CSSProperties = {
      borderRadius: '3px', // Set border radius to 0 for a square shape
      background: isHovered ? 'white' : '#0d6efd' ,
      border: `1px solid #0d6efd`,
    };
  
    const iconStyle: React.CSSProperties = {
      color: isHovered ? '#0d6efd' : 'white',
    };
  
    return (
      <Tooltip title="View Details" arrow>
        <IconButton
          style={customButtonStyle}
          onClick={onClick}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          size='small'
        >
          <Visibility style={iconStyle} fontSize='small' />
        </IconButton>
      </Tooltip>
    );
  }

  export function LinkButton({ onClick, disabled }: any) {
    const [isHovered, setIsHovered] = useState(false);
  
    const customButtonStyle: React.CSSProperties = {
      borderRadius: '3px',
      background: disabled ? '#ffc7205c' : isHovered ? 'white' : '#ffc107',
      border: `1px solid ${disabled ? '#ffc7205c' : '#ffc107'}`,
      padding: '3px',
      margin: '0 10px 0'
    };
  
    const iconStyle: React.CSSProperties = {
      color: disabled ? "#898686" : isHovered ? '#ffc107' : 'white',
    };
  
    return (
      <Tooltip title="Link to SW" arrow disableHoverListener={disabled} disableFocusListener={disabled}>
        <span>
          <IconButton
            style={customButtonStyle}
            size='small'
            onClick={onClick}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            disabled={disabled}>
            <Link style={iconStyle} />
          </IconButton>
        </span>
      </Tooltip>
    );
  }

  export function DeleteButton({ onClick }: any) {
    const [isHovered, setIsHovered] = useState(false);
  
    const customButtonStyle: React.CSSProperties = {
      borderRadius: '3px',
      background: isHovered ? 'white' : '#f93e3e',
      border: '1px solid #f93e3e',
      padding: '3px'
    };
  
    const iconStyle: React.CSSProperties = {
      color: isHovered ? '#f93e3e' : 'white',
    };
  
    return (
      Config.allowDelete ? (
          <Tooltip title="Delete record" arrow>
            <span>
              <IconButton
                style={customButtonStyle}
                size='small'
                onClick={onClick}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}>
                <DeleteForever style={iconStyle}/>
              </IconButton>
            </span>
          </Tooltip>
        )  :
        <span></span>
    );
  }

export default ViewButton;
