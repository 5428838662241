import * as React from 'react';
import { DataGrid, GridColDef, GridRowSpacingParams, GridRowHeightParams } from '@mui/x-data-grid';
import axios from 'axios';
import { Snackbar,
  LinearProgress, 
  Box,
  Modal,
  Typography,
  Grid, 
  Alert,
  Stack, 
  AlertTitle, 
  Divider,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  Tooltip } from '@mui/material';
import * as Icon from '@mui/icons-material';
import { Button, Container, Form } from 'react-bootstrap';
import { useAuth0 } from '@auth0/auth0-react';
import Config from '../helpers/Config';
import ConsoleLog from '../helpers/ConsoleLog';
import clsx from "clsx";
import ImportProgresPopup from '../helpers/dialogs/importFileProgres'
import { ViewButton, LinkButton, DeleteButton} from '../helpers/buttons/CustomButtons'

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const gridStyles = {
  height: 800,
  width: '100%',
  '& .super-app.negative': {
    backgroundColor: '#ffcd39',
    color: '#1a3e72',
    fontWeight: '600',
  }
};

interface DonationRecord {
  id: string
  title: string
  firstName: string
  lastName: string
  middleName: string
  phone: string
  email: string
  shipName: string
  createdOn: Date
  giftDate: Date
  donation: number
  voucher: number
  bookingId: number | null
  clientId: number | null
  amount: number
  address: Address
  ftcStatus: number
  travelers: Traveler[]
  isDonationApplicable: boolean
  reasonCode: string
  currency: string
  voucherId: string
}

interface Address {
  id: string
  city: string
  state: string
  zipCode: string
  country: string
  address1: string
  address2: string
}

interface Traveler {
  id: string,
  clientId: number | null,
  ftcStatus: number,
  firstName: string,
  lastName: string
}

interface LinkUserDetails{
  bookingId : number | null,
  clientId : number | null,
  travelers: Traveler[]
}

interface AlertInfo {
  open: boolean;
  severity: 'error' | 'warning' | 'info' | 'success';
  message: string;
  title: 'Error' | 'Warning' | 'Info' | 'Success';
}

interface UpdateUserDetails{
  title: string
  firstName: string
  lastName: string
  middleName: string
  email: string,
  phone: string
}

interface UpdateUserDetailsError{
  isError: boolean,
  firstNameError: boolean
  lastNameError: boolean
  emailError: boolean,
  phoneError: boolean
}

export default function Donations() {
  const [donationRecords, setDonationRecords] = React.useState<DonationRecord[]|any>();
  const [selectedItem, setSelectedItem] = React.useState<DonationRecord>();
  const [open, setOpen] = React.useState(false);
  const [linkUserDetails, setLinkUserDetails] = React.useState<LinkUserDetails>({
    bookingId: null,
    clientId: null,
    travelers: [] as Traveler[]
  });
  const [loading, setLoading] = React.useState(false);
  const [isImporting, setImporting] = React.useState(false);
  const [isLinkModalOpen, setIsLinkModalOpen] = React.useState(false);
  const [error, setError] = React.useState('');
  const handleClose = () => {
    setOpen(false);
    setUpdateUserDetails({
      email: '',
      title: '',
      firstName: '',
      lastName: '',
      middleName: '',
      phone: ''
    })
    setUpdateUserDetailsError({
      emailError: false,
      firstNameError: false,
      isError: false,
      lastNameError: false,
      phoneError: false
    })
    setIsEditDonorMode(false);
  }
  const isProdMode = (): boolean => {
    if (process.env.NODE_ENV === 'production') {
      return true;
    }
    return false;
  }
  const [showDulicateDialog, setDuplicateDialog] = React.useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = React.useState(false);
  const [serviceError, setServiceError] = React.useState(false);
  const [clientIdError, seltClientIdError] = React.useState(false);
  const [coTravelersCientIdError, setCoTravelersClientIdError] = React.useState(
    new Array(linkUserDetails?.travelers?.length).fill(false)
  );
  const [bookingIdError, seltBookingIdError] = React.useState(false);
  const fileInputRef = React.useRef<HTMLInputElement | null>(null);
  const [alert, setAlert] = React.useState<AlertInfo>({
    open: false,
    severity: 'info',
    message: '',
    title: 'Info'
  });
  const [isEditDonorMode, setIsEditDonorMode] = React.useState(false);
  const [updateUserDetails, setUpdateUserDetails] = React.useState<UpdateUserDetails>({
    email: '',
    title: '',
    firstName: '',
    lastName: '',
    middleName: '',
    phone: ''
  });
  const [updateUserDetailsError, setUpdateUserDetailsError] = React.useState<UpdateUserDetailsError>({
    emailError: false,
    firstNameError: false,
    lastNameError: false,
    isError: false,
    phoneError: false
  });

  const titleOptions = [
    {key: '- Select - ', value: '-1'},
    {key: 'Mr.', value: 'Mr.'},
    {key: 'Mrs.', value: 'Mrs.'},
    {key: 'Miss', value: 'Miss'},
    {key: 'Ms.', value: 'Ms.'},
    {key: 'Dr.', value: 'Dr.'},
    {key: 'Drs.', value: 'Drs.'},
    {key: 'Mr. and Mrs.', value: 'Mr. and Mrs.'},
    {key: 'Mr. and Dr.', value: 'Mr. and Dr.'},
    {key: 'Dr. and Mrs.', value: 'Dr. and Mrs.'},
    {key: 'The Honorable', value: 'The Honorable'},
    {key: 'Reverend', value: 'Reverend'},
  ];

  React.useEffect(() => {
    const isError =
      updateUserDetailsError.emailError ||
      updateUserDetailsError.firstNameError ||
      updateUserDetailsError.lastNameError ||
      updateUserDetailsError.phoneError;
  
    setUpdateUserDetailsError(prevState => ({
      ...prevState,
      isError: isError
    }));
  }, [updateUserDetailsError.emailError, updateUserDetailsError.firstNameError, updateUserDetailsError.lastNameError, updateUserDetailsError.phoneError]);

  const handleAlertClose = () => {
    setAlert({ ...alert, open: false });
  };
  const [isEditAllowed, setIsEditAllowed] = React.useState(false)

  let [searchParam, setSearchParams] =  React.useState<string>();
  const customFilterOperators = [
    {
      label: 'Any',
      value: 'is',
      getApplyFilterFn: () => () => {
        return true;
      },
    },
    {
      label: 'Applicable',
      value: 'has-value',
      getApplyFilterFn: (filterItem:any) => (params:any) => {
        return params.value !== null;
      },
    },
    {
      label: 'Not Applicable',
      value: 'is-null',
      getApplyFilterFn: (filterItem:any) => (params:any) => {
        return params.value === null;
      },
    },
  ];

  const columns: GridColDef[] = [
    { field: 'createdOn', headerName: 'DATE ADDED',  type: 'date', width: 110, 
      valueGetter: (params) => new Date(params.row.createdOn), // Convert ISO 8601 to Date object
      renderCell: (params) => {
        var to_show = params.value.toLocaleDateString('en-US', {
          month: 'short',
          day: 'numeric',
          year: 'numeric',
        });
        return (
          <Box sx={{ textAlign: "center" }}>
            <Tooltip title={to_show} arrow>
              <Box>
              {to_show}
              </Box>
            </Tooltip>
            {params.row.travelers.map((item:string, index:number) => (<br key={`cr_${index}`}/>))}
          </Box>
        );
      }
    },
    { field: 'reasonCode', headerName: 'REASON CODE',  type: 'string', width: 80, align: 'center', renderCell: (params) => (
      <Box>
          <Box>
            {params.value}
          </Box>
          <Box>{params.row.travelers.map((item:string, index:number) => (<br key={`em_${index}`}/>))}</Box>
       </Box>
    ), },
    {
      field: 'fullName',
      headerName: 'DONOR NAME',
      width: 210,
      valueGetter: (params) =>  `${params.row.firstName || ''} ${params.row.middleName || ''} ${params.row.lastName || ''}`,
      renderCell: (params) => {
        const items = [];
        items.push(params.value);
        params.row.travelers.map((item:Traveler) => {
          items.push(`${item.firstName} ${item.lastName}`);
        })

        return (
          <Box>
            {items.map((item, index) => (
              <Tooltip key={`fn_${index}`} title={item} arrow>
                <Box>
                    {item}
                </Box>
              </Tooltip>
            ))}
          </Box>
        );
      }
    },  
    { field: 'email', headerName: 'EMAIL', width: 240, disableColumnMenu: true, filterable: false, renderCell: (params) => { 
        return (
          <Box>
            <Tooltip title={params.row.email} arrow>
              <Box>
              {params.row.email}
              </Box>
            </Tooltip>
            {params.row.travelers.map((item:string, index:number) => (<br key={`em_${index}`}/>))}
          </Box>
        );
      }
    },
    { field: 'shipName', headerName: 'SHIP', width: 200, renderCell: (params) => {
      let shipName = params.row.shipName;
      return (
        <Box>
          <Tooltip title={shipName} arrow>
            <Box>
              {shipName.length > 25 ? `${shipName.substring(0, 25)}...` : shipName}
            </Box>
          </Tooltip>
          {params.row.travelers.map((item:string, index:number) => (<br key={`sn_${index}`}/>))}
        </Box>
      )}
    },
    { field: 'giftDate', headerName: 'GIFT DATE',  type: 'date', width: 110, 
      valueGetter: (params) =>  new Date(params.row.giftDate), // Convert ISO 8601 to Date object
      renderCell: (params) => {
        var to_show = params.value.toLocaleDateString('en-US', {
          month: 'short',
          day: 'numeric',
          year: 'numeric',
        });
        return (
          <Box sx={{ textAlign: "center" }}>
            <Tooltip title={to_show} arrow>
              <Box>
                {to_show}
              </Box>
            </Tooltip>
            {params.row.travelers.map((item:Traveler, index:number) => (<br key={`gd_${index}`}/>))}
          </Box>
        );
      }
    },
    { field: 'amount', headerName: 'GIFT', type: 'number', width: 90, disableColumnMenu: true, filterable: false, 
      renderCell: (params) => {
        var to_show = params.value == null ? 'N\\A' :  `${params.row.currency} ${params.value}`;
        var isIncorrectrequest = params.row.voucher >  params.row.amount || (params.row.voucher * (params.row.travelers.length + 1)) > params.row.amount;
        if(isIncorrectrequest){
          return (
            <Box>
              <Tooltip title="Gift amount is less than amount of requested vouchers" arrow>
                <Box>
                <Icon.ReportGmailerrorredTwoTone color="error" fontSize="small" /> {to_show}  
                </Box>
              </Tooltip>
              {params.row.travelers.map((item:string, index:number) => (<br key={`am_${index}`}/>))}
            </Box>
          );
        }
        return (
          <Box>
            <Tooltip title={to_show} arrow>
              <Box>
                {to_show}
              </Box>
            </Tooltip>
            {params.row.travelers.map((item:string, index:number) => (<br key={`am2_${index}`}/>))}
          </Box>
        );
      }
    },
    { field: 'voucher', headerName: 'FTC', type: 'boolean', width: 70,     
      valueGetter: (params) => params.row.voucher,
      filterOperators: customFilterOperators,
      renderCell: (params) => { 
          var to_show = !params.row.voucher  ? 'N\\A' :  `${params.row.currency} ${params.row.voucher}`;
          return (
            <Box>
              <Tooltip title={to_show} arrow>
                <Box>
                  {to_show}
                </Box>
              </Tooltip>
              {params.row.travelers.map((item:string, index:number) => (
                <Box sx={{ textAlign: "center", height: 20}} key={`cl_${index}`}>
                  {to_show}
                </Box>
              ))}
            </Box>
          )
        },
      // cellClassName: (params) => 
      // clsx('super-app', {
      //   negative: params.row.voucher >  params.row.amount
      // })
    },
    // { 
    //   field: 'travelers',
    //   headerName: 'FTC #',
    //   type: 'number',
    //   width: 85,
    //   filterable: false,
    //   disableColumnMenu: true,
    //   sortable: false,
    //   renderCell: (params) => { return !params.row.isVoucherRequested  ? 'N\\A' :  `${params.row.travelers.length + 1}`},
    //   valueGetter: (params) => params.row.travelers.length 
    // },
    { 
      field: 'swMatch',
      headerName: 'SW MATCH',
      type: 'boolean',
      width: 100,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => { 
        return <Box>
           {params.row.bookingId == null ||
            params.row.clientId == null ||
            params.row.travelers.some((traveler: Traveler) => traveler.clientId === null) ? 
            <Icon.ClearRounded color='warning'/> : <Icon.DoneRounded color='success'/>}
          </Box>
      },
      valueGetter: (params) => params.row.bookingId !== null && params.row.clientId !== null && !params.row.travelers.some((traveler: Traveler) => traveler.clientId === null), 
    },
    { 
      field: 'bookingId', 
      headerName: 'BOOKING ID',
      type: 'number',
      headerAlign: 'center',
      align: 'center',
      filterable: false,
      disableColumnMenu: true,
      sortable: true,
      width: 100,
      renderCell: (params) => { 
        return <Box> {params.row.bookingId ?? <Icon.ClearRounded color='warning'/> } </Box> }
    },
    { field: 'clientId', headerName: 'CLIENT ID', type: 'number', headerAlign: 'center', align: 'center', width: 100, filterable: false, disableColumnMenu: true,
      renderCell: (params) => { 
        return <Box>
          {<Box sx={{ textAlign: "center" }}>{params.row.clientId ?? <Icon.ClearRounded color='warning'/>}</Box>}
          {params.row.travelers.map((item:Traveler, index:number) => (
            <Box sx={{ textAlign: "center", height: 20}} key={`cl_${index}`}>
              {item.clientId ?? <Icon.ClearRounded color='warning'/>}
            </Box>
          ))}
        </Box>
      }
    },
    { 
      field: 'ftcStatus',
      headerName: 'FTC STATUS',
      type: 'number',
      width: 150,
      disableColumnMenu: true,
      headerAlign: 'center',
      filterable: false,
      align: 'center',
      renderCell: (params) => { 
        return <span>
          {<Box sx={{ textAlign: "center" }}>{getFtcStatus(params.row.ftcStatus)}</Box>}
          {params.row.travelers.map((item:Traveler, index:number) => (
            <Box sx={{ textAlign: "center" }} key={`ft_${index}`}>
              {getFtcStatus(item.ftcStatus)}
            </Box>
          ))}
        </span>
        }
    },
    {
      field: 'action',
      headerName: 'ACTION',
      disableColumnMenu: true,
      sortable: false,
      filterable: false,
      width:140,
      renderCell: (cellValues) => {
        return (
          <Divider>
            <ViewButton onClick={() => onRowclick(cellValues)} />
            <LinkButton onClick={() => handleLinkUser(cellValues.row)}
            disabled={
              cellValues.row.bookingId !== null &&
              cellValues.row.clientId !== null &&
              !cellValues.row.travelers.some((traveler: Traveler) => traveler.clientId === null)
            }/>
            <DeleteButton onClick={() => { setSelectedItem(cellValues.row); setShowDeleteConfirmation(true); } } />
          </Divider>
        );
      }
    },
  ];

  const { getAccessTokenSilently } = useAuth0();

  const handleLinkUser = (data : DonationRecord) => {
    setIsLinkModalOpen(true);
    setSelectedItem(data);
    setLinkUserDetails({ bookingId: data.bookingId, clientId: data.clientId, travelers: data.travelers.map(item => { return {...item}})});
  }

  const handleDuplicateDialogClose = () => {
    setDuplicateDialog(false);
  }

  const onRowclick = (data : any) => {
    let selectedItem = data.row;
    setSelectedItem(selectedItem);
    setUpdateUserDetails({...updateUserDetails, 
      email: selectedItem.email, 
      title: selectedItem.title, 
      firstName: selectedItem.firstName, 
      middleName: selectedItem.middleName, 
      lastName: selectedItem.lastName,
      phone: selectedItem.phone});
    setIsEditAllowed(selectedItem.clientId == null && selectedItem.bookingId == null)
    setOpen(true);
  }

  React.useEffect(() => {
    setLoading(true);
    fetchData();
  }, []);

  const fetchData = React.useCallback(async()=> {
    GetAndSetDonors('', setDonationRecords);
  }, [])

  const onSubmit = (event : any) => {
    event.preventDefault();
    var searchval = event.target.search.value;
    ConsoleLog.log(searchval)
    if(searchval){
    setSearchParams(searchval);
    GetAndSetDonors(searchval, setDonationRecords);
  }
  }

  const onChange = (event : any) => {
    event.preventDefault();
    let val = (event.target as HTMLButtonElement).value;
    ConsoleLog.log(val)
   if(!val){
    val += '';
    setSearchParams(val);
    GetAndSetDonors(val, setDonationRecords);
    }
  }

  const handleCloseLinkModalOpen = () =>{
    setIsLinkModalOpen(false);
    setLinkUserDetails({ bookingId: null, clientId: null, travelers: []});
    setCoTravelersClientIdError(new Array(linkUserDetails?.travelers?.length).fill(false));
    seltClientIdError(false);
    seltBookingIdError(false);
    setError('');
  }

  const handleLinkToSWDetails = (fieldName : 'bookingId' | 'clinentId', value : string, travelerId?: string) => {
    const limitedValue = fieldName === 'bookingId' ? value.slice(0, 6) : value.slice(0, 9);
    if(travelerId != null){
      linkUserDetails.travelers?.map((item:Traveler) => {
        if(item.id == travelerId)
        {
          item.clientId = limitedValue ? Number(limitedValue) : null
        }
      })
      setLinkUserDetails({...linkUserDetails, travelers: linkUserDetails.travelers})
    }else{
      fieldName === 'bookingId' ? setLinkUserDetails({ ...linkUserDetails, bookingId: limitedValue ? Number(limitedValue) : null }) :
      setLinkUserDetails({ ...linkUserDetails, clientId: limitedValue ? Number(limitedValue) : null });
    }
  };

  async function GetAndSetDonors(searchparam: string | null, setDonationRecords: React.Dispatch<any>) {
      try {
        ConsoleLog.log(process.env)
        const accessToken = await getAccessTokenSilently();
        const config = {
          headers: { Authorization: `Bearer ${accessToken}` }
        };
        ConsoleLog.log(accessToken);
        ConsoleLog.warn(searchParam);
        const encodedSearchParam = encodeURIComponent(searchparam || '');
        axios.get<DonationRecord[]>(`${Config.baseURL}/GetAllAvailableDonators?search=${encodedSearchParam}`, config) //  /GetAll?search=" + searchparam, config)
          .then(response => 
            {
              setDonationRecords(response.data);
              setServiceError(false);
              setLoading(false);
            })
          .catch((error) => {
            setLoading(false);
            setServiceError(true);
          });
      } catch (e) {
        ConsoleLog.log(e);
      }
  }

  async function ExportToExcel() {
      ConsoleLog.log("Exporting Started")
      const accessToken = await getAccessTokenSilently();
      const config = {
        headers: { Authorization: `Bearer ${accessToken}` }
      };
      axios.get(`${Config.baseURL}/CsvExport?exportFormat=1`, config).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        const currentDate = new Date();
        const formattedDate = currentDate.toLocaleDateString().replace(/\//g, '-');
        let file_name = `NGDonations_${formattedDate}.csv`;
        link.setAttribute('download', file_name);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        setLoading(false);
        setAlert({open: true, message: "Unable to export data. Please try again later.", severity: 'error', title: 'Error'});
      });
  }

  async function handleFileUpload() {
    if (!fileInputRef.current) {
      return;
    }
    const file = fileInputRef.current.files?.[0];
    if (file && file.type === "text/csv") {
        setImporting(true);
        const formData = new FormData();
        formData.append('file', file);
        const accessToken = await getAccessTokenSilently();
        const config = {
            headers: { Authorization: `Bearer ${accessToken}`, 'Content-Type': 'multipart/form-data' }
        };
        ConsoleLog.log(accessToken);
        axios.post(`${Config.baseURL}/UploadDonators`, formData, config).then((response) => 
          {
            if(response.data.duplicatedRecordsCount > 0){
              setAlert({open: true, message: `${response.data.donors.length} unique record(s) were imported.
              \n${response.data.duplicatedRecordsCount} duplicate record(s) were ignored.`, severity: 'warning', title: 'Warning'});
            }else{
              setAlert({open: true, message: "File successfully imported and applied.", severity: 'success', title: 'Success'});
            }

            if(donationRecords.length === 0)
            {
              setDonationRecords(response.data.donors);
            }else if(response.data.donors.length !== 0){
              setDonationRecords((prevRecords: DonationRecord[]) => [...prevRecords, ...response.data.donors]);
            }
            setImporting(false);
          })
          .catch((error) => {
            if(error?.response?.status === 409){
                setDuplicateDialog(true)
            }else{
              setAlert({open: true, message: "Unexpected error, please try gain later.", severity: 'error', title: 'Error'});
            }
            ConsoleLog.log(error)
            setImporting(false)
          });
    } else {
      // Show an error message to the user if the selected file is not a CSV
      setAlert({open: true, message: 'Please select a valid CSV file.', severity: 'error', title: 'Error'});
    }
    fileInputRef.current.value = '';
  };

  const handleLinkConfirm = async () => {
    try {
      const isClientIdValid = /^(?=.*[1-9])\d{1,9}$/.test(`${linkUserDetails?.clientId}`);
      const isBookingIdValid = /^(?=.*[1-9])\d{6}$/.test(`${linkUserDetails?.bookingId}`);
      var coTravelerClientIdValid = true;
      if (linkUserDetails?.travelers?.some(x => x)) {
        const updatedErrors = linkUserDetails.travelers.map((traveler) => {
          const isClientIdValid = /^(?=.*[1-9])\d{1,9}$/.test(`${traveler?.clientId}`);
          return !isClientIdValid; // true if clientId is invalid, false if it's valid
        });
        coTravelerClientIdValid = !updatedErrors.some(x => x);
        setCoTravelersClientIdError(updatedErrors);
      }
      seltClientIdError(!isClientIdValid);
      seltBookingIdError(!isBookingIdValid);

      if(isClientIdValid && isBookingIdValid && coTravelerClientIdValid)
      {
          let id = selectedItem?.id;
          const accessToken = await getAccessTokenSilently();
          const config = {
            headers: { Authorization: `Bearer ${accessToken}` }
          };
          await axios.put(
            Config.baseURL + `/LinkUser/${id}`,
            linkUserDetails,
            config
          ).then((response) => {
              ConsoleLog.log(response);
              setIsLinkModalOpen(false);
              ConsoleLog.warn(selectedItem?.id);
              if (selectedItem) {
                setDonationRecords((prevItems : DonationRecord[]) =>
                  prevItems.map((item) =>
                    item.id === selectedItem.id
                      ? { ...item, ...linkUserDetails }
                      : item
                  )
                );
                setLinkUserDetails({bookingId: null, clientId: null, travelers: []})
                seltClientIdError(false);
                seltBookingIdError(false);
              }
              setAlert({open: true, message: "Successfully link user to Seaware.", severity: 'success', title: 'Success'});
            }
        ).catch((data) => {
          setError(data.response?.data || data.message);
        });
      }
    } catch (error) {
      setError('An error occurred. Please try again.');
    }
  };

  const handleDeleteConfirm = async () => {
    try {
          let id = selectedItem?.id;
          const accessToken = await getAccessTokenSilently();
          const config = {
            headers: { Authorization: `Bearer ${accessToken}` }
          };
          await axios.delete(
            Config.baseURL + `/DeleteRecord/${id}`,
            config
          ).then(() => {
              setDonationRecords((prevRecords : DonationRecord []) =>
                prevRecords.filter((record) => record.id !== id)
              );
              setShowDeleteConfirmation(false);
              setAlert({open: true, message: "Record deleted successfully.", severity: 'success', title: 'Success'});
            }
        )
    } catch (error) {
      setError('An error occurred. Please try again.');
    }
  };

  const handleSendFTCConfirm = async () => {
    try {
        let id = selectedItem?.id;
        const accessToken = await getAccessTokenSilently();
        const config = {
          headers: { Authorization: `Bearer ${accessToken}` }
        };

        await axios.post(
          Config.baseURL + `/SendFtc/${id}`,
          linkUserDetails,
          config
        ).then(() => {
            if (selectedItem) {
              setDonationRecords((prevItems : DonationRecord[]) =>
              prevItems.map((item) => {
                if (item.id === selectedItem.id) {
                  const updatedItem = { ...item, ftcStatus: 3 };
                  const updatedTravelers = item.travelers.map((traveler) => ({
                    ...traveler,
                    ftcStatus: 3,
                  }));
                  return { ...updatedItem, travelers: updatedTravelers };
                } else {
                  // If the current item is not the selectedItem, return it unchanged
                  return item;
                }
              })
              );
            }
            setAlert({open: true, message: "FTC has been sent.", severity: 'success', title: 'Success'});
            handleClose();
        }).catch((data) => {
        setError(data.response?.data || data.message);
      });
    } catch (error) {
      setError('An error occurred. Please try again.');
    }
  };

  const handleUpdateDonorConfirm = async () => {
    const emailRegex = /(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const isError = !emailRegex.test(updateUserDetails.email)
      || updateUserDetails.firstName === ''
      || updateUserDetails.lastName === ''
    setUpdateUserDetailsError({...updateUserDetailsError, 
      emailError: !emailRegex.test(updateUserDetails.email),
      firstNameError: updateUserDetails.firstName === '',
      lastNameError: updateUserDetails.lastName === ''
    })

    if(!isError){
      let id = selectedItem?.id;
      const accessToken = await getAccessTokenSilently();
      const config = {
        headers: { Authorization: `Bearer ${accessToken}` }
      };

      await axios.put(
        Config.baseURL + `/UpdateDonor/${id}`,
        updateUserDetails,
        config
      ).then(() => {          
          if (selectedItem) {
            setDonationRecords((prevItems : DonationRecord[]) =>
              prevItems.map((item) =>
                item.id === selectedItem.id
                  ? { ...item, ...updateUserDetails }
                  : item
              )
            );
          }
          handleClose();
          setAlert({open: true, message: "Donor details were successfully updated.", severity: 'success', title: 'Success'});
        }).catch((data) => {
          ConsoleLog.log(data);
          setAlert({open: true, message: "Unable to update donor.", severity: 'error', title: 'Error'});
        });
      }
  };

  const handleKeyDown = (event: any) => {
    const isMac = /Mac/.test(navigator.platform);
    const allowedKeyCodes = [
      // Allow control keys, navigation keys, and other special keys
      8, // Backspace
      9, // Tab
      37, // ArrowLeft
      39, // ArrowRight
      46, // Delete
      91, // OS Command
      93, // Context Menu (Right Click)

      // Allow numeric keypad keys
      96, 97, 98, 99, 100, 101, 102, 103, 104, 105,

      // Allow common key combinations
      16, // Shift
      17, // Control
      18, // Alt
      224, // Command (Mac)
    ];
    // Allow Ctrl key combinations
    if (((isMac && event.metaKey) || (!isMac && event.ctrlKey)) && (event.key === 'a' || event.key === 'c' || event.key === 'v' || event.key === 'x' || event.key === 'z')) {
      return;
    }

    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    // Check if the pressed key is a valid numeric character or a backspace
    if (!/^[0-9\b]+$/.test(keyValue)
        && !allowedKeyCodes.includes(event.keyCode)) {
      event.preventDefault();
    }
  };

  const handleKeyDownPhone = (event:any) => {
    const isMac = /Mac/.test(navigator.platform);
    const allowedKeyCodes = [
      // Allow control keys, navigation keys, and other special keys
      8, // Backspace
      9, // Tab
      37, // ArrowLeft
      39, // ArrowRight
      46, // Delete
      91, // OS Command
      93, // Context Menu (Right Click)

      // Allow numeric keypad keys
      96, 97, 98, 99, 100, 101, 102, 103, 104, 105,

      // Allow common key combinations
      16, // Shift
      17, // Control
      18, // Alt
      224, // Command (Mac)
    ];
    // Allow Ctrl key combinations
    if (((isMac && event.metaKey) || (!isMac && event.ctrlKey)) && (event.key === 'a' || event.key === 'c' || event.key === 'v' || event.key === 'x' || event.key === 'z')) {
      return;
    }

    const allowedKeys = ['+', '-', '(', ')'];

    if (
      !/[0-9]/.test(event.key) &&
      !allowedKeys.includes(event.key) &&
      !allowedKeyCodes.includes(event.keyCode) && // Allow specific key codes
      (event.key.length === 1 || event.key === ' ' || event.key === 'Dead') // Prevent text characters
    ) {
      event.preventDefault();
    }
  };

  const handleToggleEditAddress = () => {
    setIsEditDonorMode(!isEditDonorMode);
  };

  const handleFieldChange = (fieldName : 'email' | 'title' | 'firstName' | 'lastName' | 'middleName' | 'phone', value : string) => {
    let allowedLength = 0;

    switch(fieldName)
    {
        
      case 'title':
        allowedLength = 15;
        break;
      case 'email':  
      case 'firstName':
      case 'lastName':
      case 'middleName':
        allowedLength = 50;
        break;
      case 'phone':
        allowedLength = 25;
        break;
    }

    if(value.length <= allowedLength)
      setUpdateUserDetails((prevDetails) => ({
        ...prevDetails,
        [fieldName]: value,
      }));
  };

  const enumMapping: { [key: number]: string } = {
    0: 'N\\A',
    1: 'Not Generated',
    2: 'Generated',
    3: 'Sent',
    4: 'Received'
  };
  
  const getFtcStatus = (value: number): string => {
    return enumMapping[value];
  };

  const getRowHeight = (params:any) => {
    // Calculate the height based on content, but ensure it doesn't go below the minimum height
    const contentHeight = params.model && params.model.travelers.length > 0 ? ((params.model.travelers.length + 1) * 20) + 10  : 0; // Adjust the multiplier as needed
    return Math.max(52, contentHeight);
  };

  return (
    <>
    <Container className='p-3'>
      <Form onSubmit={onSubmit} onChange={onChange} className="d-flex justify-content-between">
          <Form.Control style={{width: '65%'}}
            type="search"
            placeholder="Search"
            className="me-2"
            aria-label="Search"
            name="search"
          />
        <Button style={{marginRight: '8rem'}} type="submit" className='btn btn-primary'>Search</Button>{' '}
        <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
          <label htmlFor="fileInput" className='btn btn-warning'>
            Import File
            <input
              ref={fileInputRef}
              id="fileInput"
              type="file"
              accept=".csv"
              capture="environment"
              style={{ display: 'none' }} // Hide the input but keep the label as a styled button
              onChange={handleFileUpload}
            />
          </label>
          <ImportProgresPopup open={isImporting} onClose={() => setImporting(false)} />
          <Button type="button" onClick={ExportToExcel} className='btn btn-primary' disabled={false}>Export</Button>{' '}
        </Box>
      </Form>
    </Container>
<div className="d-grid gap-2">
</div>
    {!loading && !serviceError && (
       <Box sx={gridStyles}>
        <DataGrid
          rows={donationRecords ?? []}
          columns={columns}
          //getRowHeight={() => 'auto'}
          isRowSelectable={() => false}
          getRowHeight={getRowHeight}
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
          }
          sx={{
            '& .odd': {
              backgroundColor: '#ECF1FD', // Change odd row background color
            },
          }}
          //onCellClick={onRowclick}
          //scheckboxSelection
        />
      </Box>
    )}
    {loading && (
        <Box sx={{ width: '100%' }}>
          <LinearProgress />
        </Box>
      )}
    {!loading && serviceError && (
      <Stack sx={{ width: '100%' }} spacing={2}>
      <Alert severity="error">
        <AlertTitle>Error</AlertTitle>
          Service currently unavailable, please try again later. 
        </Alert>      
      </Stack>
    )}
    <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableEnforceFocus
      >
        <Box sx={style}>
          {selectedItem && (
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="subtitle2" color="#84919A">DONOR</Typography>
                <Icon.Edit
                        onClick={handleToggleEditAddress}
                        color={isEditDonorMode ? 'success' : 'primary'}
                        fontSize='small'
                        style={{ margin: '-5px 0 0 8px', cursor: 'pointer' }}/>
                </div>
                {isEditAllowed && isEditDonorMode ? (
                   <div>
                      <TextField
                        select
                        value={!titleOptions.some(option => option.value === updateUserDetails.title) ? '-1' : updateUserDetails.title}
                        onChange={(e) => handleFieldChange('title', e.target.value !== '-1' ? e.target.value : '')}
                        type="text"
                        size='small'
                        fullWidth
                        margin="dense">
                        {
                          titleOptions.map((option) => (
                            <MenuItem key={option.key} value={option.value}>
                              {option.key}
                            </MenuItem>))
                        }
                      </TextField>
                      <TextField
                        value={updateUserDetails.firstName}
                        onChange={(e) => handleFieldChange('firstName', e.target.value)}
                        type="text"
                        placeholder='First Name'
                        required
                        size='small'
                        fullWidth
                        margin="dense"
                        error={updateUserDetailsError.firstNameError}
                        helperText={updateUserDetailsError.firstNameError ? 'First name is required' : ''}
                      />
                      <TextField
                        value={updateUserDetails.middleName}
                        onChange={(e) => handleFieldChange('middleName', e.target.value)}
                        type="text"
                        placeholder='Middle Name'
                        size='small'
                        fullWidth
                        margin="dense"
                      />
                      <TextField
                        value={updateUserDetails.lastName}
                        onChange={(e) => handleFieldChange('lastName', e.target.value)}
                        type="text"
                        placeholder='Last Name'
                        required
                        size='small'
                        fullWidth
                        margin="dense"
                        error={updateUserDetailsError.lastNameError}
                        helperText={updateUserDetailsError.lastNameError ? 'Last name is required' : ''}
                      />
                </div>
                ) :(
                    <Typography sx={{ overflow: 'hidden', wordBreak: 'break-all' }}>
                      {selectedItem.title} {selectedItem.firstName} {selectedItem.middleName} {selectedItem.lastName} (Donor)
                    </Typography>
                )}
                <Typography variant="subtitle2" color="#84919A">EMAIL </Typography>
                {isEditDonorMode ? (
                  <TextField
                    value={updateUserDetails.email}
                    onChange={(e) => handleFieldChange('email', e.target.value)}
                    type="email"
                    placeholder='Email address'
                    required
                    size='small'
                    fullWidth
                    margin="none"
                    error={updateUserDetailsError.emailError}
                    helperText={updateUserDetailsError.emailError ? 'Valid email is required' : ''}
                  />
                ) :(
                    <Typography sx={{ overflow: 'hidden', wordBreak: 'break-all' }}>{selectedItem.email}</Typography>
                )}
                <Typography variant="subtitle2" color="#84919A">PHONE</Typography>
                {isEditAllowed && isEditDonorMode ? (
                  <TextField
                    value={updateUserDetails.phone}
                    onKeyDown={handleKeyDownPhone}
                    onChange={(e) => handleFieldChange('phone', e.target.value)}
                    type="text"
                    placeholder='Phone number'
                    size='small'
                    fullWidth
                    margin="none"
                    inputProps={{
                      maxLength: 25,
                    }}
                    error={updateUserDetailsError.phoneError}
                    helperText={updateUserDetailsError.phoneError ? 'Valid phone number is required' : ''}
                  />
                ) :(
                  <Typography>{selectedItem.phone || '-'}</Typography>
                )}
                <Typography variant="subtitle2" color="#84919A">ADDRESS 1</Typography>
                <Typography sx={{ overflow: 'hidden', wordBreak: 'break-all' }}>{selectedItem.address.address1}</Typography>
                <Typography variant="subtitle2" color="#84919A">ADDRESS 2</Typography>
                <Typography sx={{ overflow: 'hidden', wordBreak: 'break-all' }}>{selectedItem.address.address2 || '-'}</Typography>
                <Typography variant="subtitle2" color="#84919A">CITY</Typography>
                <Typography sx={{ overflow: 'hidden', wordBreak: 'break-all' }}>{selectedItem.address.city || '-'}</Typography>
                <Typography variant="subtitle2" color="#84919A">STATE</Typography>
                <Typography sx={{ overflow: 'hidden', wordBreak: 'break-all' }}>{selectedItem.address.state || '-'}</Typography>
                <Typography variant="subtitle2" color="#84919A">ZIP CODE</Typography>
                <Typography sx={{ overflow: 'hidden', wordBreak: 'break-all' }}>{selectedItem.address.zipCode|| '-'}</Typography>
                <Typography variant="subtitle2" color="#84919A">COUNTRY</Typography>
                <Typography sx={{ overflow: 'hidden', wordBreak: 'break-all' }}>{selectedItem.address.country || '-'}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle2" color="#84919A">FTC RECIPIENTS</Typography>
                <Typography sx={{ overflow: 'hidden', wordBreak: 'break-all' }}>
                  {
                    selectedItem.voucher == null ? 'N\\A' :
                    `${selectedItem.title} ${selectedItem.firstName} ${selectedItem.middleName} ${selectedItem.lastName} (Donor)`
                  }
                </Typography>
                {selectedItem.travelers.map((traveler) => (
                  <Typography sx={{ overflow: 'hidden', wordBreak: 'break-all' }} key={`${traveler.firstName}-${traveler.lastName}`}>
                    {traveler.firstName} {traveler.lastName}
                  </Typography>
                ))}
              </Grid>
            </Grid>
          )}
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2, alignItems: 'center'}}>
            <Tooltip title="Possible to send only for confirmed donors with FTC" disableHoverListener={!isEditDonorMode && (selectedItem?.voucher !== null && (selectedItem?.clientId !== null || selectedItem?.bookingId !== null))} placement="top" arrow>
              <span>
                <Button onClick={handleSendFTCConfirm} 
                        className='btn-primary'
                        disabled={(selectedItem?.voucherId === "" || selectedItem?.voucherId === null) || isEditDonorMode || (selectedItem?.clientId === null || selectedItem?.bookingId === null || selectedItem?.voucher === null)}>Send FTC</Button>
              </span>
            </Tooltip>
            <Box alignItems="flex-end">
              <Button onClick={handleUpdateDonorConfirm} className='btn-neutral' style={{marginRight: 10}} hidden={!isEditDonorMode}>Update</Button>
              <Button onClick={handleClose} className='btn-warning'>Close</Button>
            </Box>
          </Box>
        </Box>
      </Modal>

      <Modal open={isLinkModalOpen} onClose={handleCloseLinkModalOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style}>
          <Box sx={{ marginBottom: 1, typography: 'body2' }}>We were not able to match the donor’s details with any records in Seaware.
          It might have happened because the of incorrect name or email.
          Please input the correct client ID and booking ID from Seaware to confirm the identity.
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                label="BOOKING ID"
                value={linkUserDetails?.bookingId ?? ''}
                onChange={(e) => handleLinkToSWDetails('bookingId', e.target.value)}
                onKeyDown={handleKeyDown}
                type="text"
                required
                fullWidth
                error={bookingIdError}
                margin="normal"
                helperText={bookingIdError ? 'Booking Id should be 6 digits' : ''}
                autoComplete="off"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label={`${selectedItem?.firstName} ${selectedItem?.lastName}`}
                placeholder='CLIENT ID'
                value={linkUserDetails?.clientId ?? ''}
                onChange={(e) => handleLinkToSWDetails('clinentId', e.target.value)}
                onKeyDown={handleKeyDown}
                type="text"
                required
                fullWidth
                error={clientIdError}
                margin="normal"
                helperText={clientIdError ? 'Client Id should be up to 9 digits' : ''}
                autoComplete="off"
              />
            {
              linkUserDetails?.travelers?.map((item:Traveler, index:number) => (
                  <TextField
                    label={`${item.firstName} ${item.lastName}`}
                    placeholder='CLIENT ID'
                    key={`clt_${index}`}
                    value={item.clientId ?? ''}
                    onChange={(e) => handleLinkToSWDetails('clinentId', e.target.value, item.id)}
                    onKeyDown={handleKeyDown}
                    type="text"
                    required
                    fullWidth
                    error={coTravelersCientIdError[index]}
                    margin="normal"
                    helperText={coTravelersCientIdError[index] ? 'Client Id should be up to 9 digits' : ''}
                    autoComplete="off"
                  />)
              )
            }
             </Grid>
          </Grid>
          {error && (
            <Typography color="error" sx={{ mt: 1 }}>{error}</Typography>
          )}
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2}}>
            <Button onClick={handleCloseLinkModalOpen} className='btn-neutral' style={{marginRight: 10}}>Cancel</Button>
            <Button onClick={handleLinkConfirm} className='btn-warning'>Confirm</Button>
          </Box>
        </Box>
      </Modal>
      <Dialog
        open={showDulicateDialog}
        onClose={handleDuplicateDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        color='warning'
      >
        <DialogTitle id="alert-dialog-title">
          {"Duplicate file notice"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
              The file with exact same content has been already imported, please proceed with another file.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDuplicateDialogClose} autoFocus>Confirm</Button>
        </DialogActions>
      </Dialog>
      <Snackbar open={alert.open} autoHideDuration={6000} onClose={handleAlertClose} anchorOrigin={{vertical: 'bottom', horizontal: 'left' }}>
        <Alert onClose={handleAlertClose} variant="filled" severity={alert.severity} sx={{ width: '100%' }}>
          <AlertTitle>{alert.title}</AlertTitle>
          <Typography variant="body2" dangerouslySetInnerHTML={{ __html: alert.message.replace(/\n/g, '<br/>') }}></Typography>
        </Alert>
      </Snackbar>
      <Dialog
        open={showDeleteConfirmation}
        onClose={() => { setShowDeleteConfirmation(false) } }
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        color='warning'
      >
        <DialogTitle id="alert-dialog-title">
          {"Confirmation"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete this record?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowDeleteConfirmation(false) } className='btn-neutral' autoFocus>Cancel</Button>
          <Button onClick={handleDeleteConfirm} className='btn-warning'>Confirm</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}