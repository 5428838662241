import Container from 'react-bootstrap/Container';
import { Nav, Navbar } from 'react-bootstrap';
import Profile from '../helpers/Profile';

export function FexcoNavbar() {
  
    return (
      <div className="bd-content">
        <Navbar bg="p-3 mb-2 body" expand="lg">
          <Container>
            <img src={require('../../logo.png')} style={{width: '10%'}} alt="" />
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto">
                  <Nav.Link href="/donations">DONATIONS</Nav.Link>
                </Nav>
                <Profile/>
              </Navbar.Collapse>
              {/* <LogoutButton/> */}
          </Container>
        </Navbar>
      </div>
    );
}